import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MenuService {
 menu: BehaviorSubject<any> = new BehaviorSubject(null);

  constructor() { }

  public menuaction(value) {
    console.log(this.menu)
    if(value && value !== null) {
      this.menu.next(value);
    } else {
      console.log(this.menu)
      this.menu.next('open')
    }

  }
}
