import { NgModule } from '@angular/core';
import { ScrollVanishDirective } from '../directives/scroll-vanish.directive';
import { HoldableDirective } from '../directives/holdable.directive'
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatMenuModule } from '@angular/material/menu';
import { AngularFireModule } from '@angular/fire';
import { environment } from 'src/environments/environment';


const directives = [
    HoldableDirective,
    ScrollVanishDirective
]

@NgModule({
    declarations: [
        directives
    ],
    imports: [
        IonicModule,
        CommonModule, 
        FormsModule,
        MatMenuModule,
        IonicModule,
        AngularFireModule.initializeApp(environment.firebase)
    ],
    exports: [
        CommonModule,
        MatMenuModule,
        FormsModule,
        IonicModule,
        directives
    ]
})

export class SharedModule {
    
 }

