import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class SeoService {

  constructor(
    private meta: Meta,
    public title: Title,
    ) {

     }

  generateTags(config) {
    // default values
    config = { 
      title: config.title != null ? config.title : 'page.linky', 
      description: config.bio != null ? config.bio : 'Custom content page, made using elinky.app', 
      image: config.img != null ? config.img : 'https://firebasestorage.googleapis.com/v0/b/the-linky-app.appspot.com/o/appimages%2Fundraw_connected_world_wuay.svg?alt=media&token=f0eb915f-2da0-4f29-b5ee-dc950ccb8c5a',
      slug: '',
      ...config
    }
    this.title.setTitle(config.title);
    this.meta.updateTag({ name: 'twitter:card', content: 'summary' });
    this.meta.updateTag({ name: 'twitter:site', content: 'page.linky.app' });
    this.meta.updateTag({ name: 'twitter:title', content: config.title });
    this.meta.updateTag({ name: 'twitter:description', content: config.description });
    this.meta.updateTag({ name: 'twitter:image', content: config.image });

    this.meta.updateTag({ property: 'og:type', content: 'article' });
    this.meta.updateTag({ property: 'og:site_name', content: 'page.linky.app' });
    this.meta.updateTag({ property: 'og:title', content: config.title });
    this.meta.updateTag({ property: 'og:description', content: config.description });
    this.meta.updateTag({ property: 'og:image', content: config.image });
    this.meta.updateTag({ property: 'og:url', content: `https://page.linky.app/${config.slug}` });
  }
}
