import { Directive, HostListener, EventEmitter, Output, HostBinding } from '@angular/core';
import { Subject, Observable, interval } from 'rxjs';
import { filter, tap, takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[holdable]'
})
export class HoldableDirective {
  pressing: boolean;
  longPressing: boolean;
  timeout: any;
  interval: any;
  @Output()
  onLongPress = new EventEmitter();
  @Output()
  onLongSwipe = new EventEmitter();
  @Output()
  onAction = new EventEmitter();
  @Output()
  onLongPressing = new EventEmitter();
  num: number;
  touchobj: any;
  touchstart: any;
  startTime: number;
  swipedir: string;
  dist: number;
  startX: any;
  startY: any;
  threshold: number;
  restraint: number;
  allowedTime: number;
  longSwipe: any;
  slope: number;
  action: any;
  num2: any;
  interval2: any;
  timeout2: any;

  @HostBinding('class.press')
  get press() { return this.pressing; }

  @HostBinding('class.longpress')
  get longPress() { return this.longPressing; }

  @HostBinding('class.swipe')
  get swipe() { return this.longSwipe; }

  @HostBinding('class.controller')
  get controller() { return this.action; }

  @HostListener('touchstart', ['$event'])
  @HostListener('mousedown', ['$event'])
  onMouseDown(event) {
    this.pressing = true;
    this.longPressing = false;
    this.num = 0 ;
    this.threshold = 150, 
    this.restraint = 150,
    this.allowedTime = 300,
    this.touchstart = event.changedTouches[0]
    this.swipedir = 'none'
    this.dist = 0
    this.startX = this.touchstart.pageX
    this.startY = this.touchstart.pageY
    this.startTime = new Date().getTime() // record time when finger first makes contact with surface
    this.timeout = setTimeout(() => {
      this.longPressing = true;
      this.onLongPress.emit(event);
      this.touchobj = event.changedTouches[0];
      this.interval = setInterval(() => {
        this.num += 1;
        this.onLongPressing.emit(this.num);
        this.onLongPress.emit(event);
      }, 50);
    }, 500);
  }
  @HostListener('touchmove', ['$event'])
  actionPress(event) {
    this.timeout2 = setTimeout(() => { 
      this.touchobj = event.changedTouches[0];
      this.interval2 = setInterval(() => { 
        var distX = this.touchobj.pageX - this.startX // get horizontal dist traveled by finger while in contact with surface
        var distY = this.touchobj.pageY - this.startY
        this.slope = distY / distX
        var angle = Math.atan2(distX, distY) * (180 / Math.PI);
        if (angle < 0) {
          angle = Math.abs(angle);
        } else {
          angle = 360 - angle;
        }
        var velocity = Math.sqrt( distX + distY)
        if (Math.abs(distX) >= this.threshold && Math.abs(distY) <= this.restraint){ // 2nd condition for horizontal swipe met
          if(this.num2 <= 360 && this.num2 >= 0) {
          this.num2 += (distY < 0)? 1 : -1;
          }
          this.swipedir = (distX < 0)? 'left' : 'right' // if dist traveled is negative, it indicates left swipe
        }
        else if (Math.abs(distY) >= this.threshold && Math.abs(distX) <= this.restraint){ // 2nd condition for vertical swipe met
          if(this.num2 <= 360 && this.num2 >= 0) {
            this.num2 += (distY < 0)? 1 : -1;
            }
          this.swipedir = (distY < 0)? 'up' : 'down' // if dist traveled is negative, it indicates up swipe
        }
        let obj = {
          num: this.num2,
          x: distX, 
          y:distY, 
          direction: this.swipedir, 
          slope: this.slope, 
          angle: angle, 
          speed: velocity
        }
        this.onAction.emit(this.num2)
      }, 50)
    }, 500)
  }
  @HostListener('touchend', ['$event'])
  @HostListener('mouseup', ['$event'])
  @HostListener('mouseleave', ['$event'])
  endPress(event) {
    clearTimeout(this.timeout);
    clearInterval(this.interval);
    this.longPressing = false;
    this.pressing = false;
    this.num = 0;
    this.num2 = 0;
    this.timeout2 = null
    this.interval2 = null
    this.onLongPress.emit(event);
    var e = event
    this.touchobj = e.changedTouches[0]
    var distX = this.touchobj.pageX - this.startX // get horizontal dist traveled by finger while in contact with surface
    var distY = this.touchobj.pageY - this.startY // get vertical dist traveled by finger while in contact with surface
    var elapsedTime = new Date().getTime() - this.startTime // get time elapsed
        if (elapsedTime <= this.allowedTime){ // first condition for awipe met
            if (Math.abs(distX) >= this.threshold && Math.abs(distY) <= this.restraint){ // 2nd condition for horizontal swipe met
                this.swipedir = (distX < 0)? 'left' : 'right' // if dist traveled is negative, it indicates left swipe
            }
            else if (Math.abs(distY) >= this.threshold && Math.abs(distX) <= this.restraint){ // 2nd condition for vertical swipe met
                this.swipedir = (distY < 0)? 'up' : 'down' // if dist traveled is negative, it indicates up swipe
            }
        }
        this.onLongSwipe.emit(this.swipedir)
  }
}