import { Directive, Input, ElementRef, Renderer2, OnInit, Output, EventEmitter } from "@angular/core";
import { DomController } from "@ionic/angular";

@Directive({
  selector: "[myScrollVanish]"
})
export class ScrollVanishDirective implements OnInit {
  @Input("myScrollVanish") scrollArea;
  @Output() selected: EventEmitter<any> = new EventEmitter();
  private hidden: boolean = false;
  private triggerDistance: number = 20;

  constructor(
    private element: ElementRef,
    private renderer: Renderer2,
    private domCtrl: DomController
  ) {}

  ngOnInit() {
    this.initStyles();

    if(this.scrollArea != null || undefined) {
      this.scrollArea.ionScroll.subscribe(scrollEvent => {
        let delta = scrollEvent.detail.deltaY;
        if (scrollEvent.detail.currentY === 0 && this.hidden) {
          this.show();
        } else if (!this.hidden && delta > this.triggerDistance) {
          this.hide();
        } else if (this.hidden && delta < -this.triggerDistance) {
          this.show();
        }
        this.selected.emit(this.hidden)
      });
    }
  }

  initStyles() {
    this.domCtrl.write(() => {
      this.renderer.setStyle(
        this.element.nativeElement,
        "transition",
        "0.2s linear"
      );
    });
  }

  hide() {
    this.domCtrl.write(() => {
      this.renderer.setStyle(this.element.nativeElement, "height", "95%");
      this.renderer.setStyle(this.element.nativeElement, "height", "90%");
      this.renderer.setStyle(this.element.nativeElement, "height", "85%");
      this.renderer.setStyle(this.element.nativeElement, "height", "75%");
      this.renderer.setStyle(this.element.nativeElement, "height", "65%");
      this.renderer.setStyle(this.element.nativeElement, "height", "55%");
      this.renderer.setStyle(this.element.nativeElement, "height", "45%");
      this.renderer.setStyle(this.element.nativeElement, "height", "25%");
      this.renderer.setStyle(this.element.nativeElement, "height", "5%");
      this.renderer.setStyle(this.element.nativeElement, "height", "0px");
    });

    this.hidden = true;
  }

  show() {
    this.domCtrl.write(() => {
      this.renderer.setStyle(this.element.nativeElement, "height", "1%");
      this.renderer.setStyle(this.element.nativeElement, "height", "5%");
      this.renderer.setStyle(this.element.nativeElement, "height", "25%");
      this.renderer.setStyle(this.element.nativeElement, "height", "45%");
      this.renderer.setStyle(this.element.nativeElement, "height", "55%");
      this.renderer.setStyle(this.element.nativeElement, "height", "65%");
      this.renderer.setStyle(this.element.nativeElement, "height", "75%");
      this.renderer.setStyle(this.element.nativeElement, "height", "85%");
      this.renderer.setStyle(this.element.nativeElement, "height", "90%");
      this.renderer.setStyle(this.element.nativeElement, "height", "95%");
      this.renderer.setStyle(this.element.nativeElement, "height", "auto");
    });
    this.hidden = false;
  }
}