import { Injectable, Inject, PLATFORM_ID, OnInit } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase/app';
import 'firebase/auth'
import 'firebase/messaging'
import 'rxjs/add/operator/take';
import { BehaviorSubject } from 'rxjs'
import { isPlatformBrowser } from '@angular/common';
import { Params, ActivatedRoute } from '@angular/router';
import { WINDOW, BrowserWindowRef, WindowRef } from "./window-ref.service"
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { GlobalService } from './global.service';
import { Platform } from '@ionic/angular';

@Injectable()
export class MessagingService implements OnInit {
  currentMessage = new BehaviorSubject(null);
  navigator: any;
  isBrowser: boolean;
  userContent: any;
  token: Promise<string>;
  tokenraw: string;
  registration: void;
  currenturl: string;
  user: firebase.default.User;
  cookiedata: string;
  currentowner: any;
  requireslogin: boolean;
  deviceupdate: boolean;

  constructor(
    private db: AngularFireDatabase,
    private afAuth: AngularFireAuth,
    public snackBar: MatSnackBar,
    public route: ActivatedRoute,
    public dialog: MatDialog,
    private platform: Platform,
    public global: GlobalService,
    @Inject(PLATFORM_ID) private platformId: string,
    @Inject(WindowRef) private window: Window
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    if (this.isBrowser && !this.platform.is('ios')) {
      firebase.default.messaging().onMessage((payload) => {
        console.log("Message received. ", payload);
        this.snackBar.open(payload, 'ok!', {
          duration: 9000
        });
        this.currentMessage.next(payload)
      });
    }
  }

  ngOnInit() {
    if (this.isBrowser) {
      console.log('ngOnInit -- Service Worker getting registation')
      /*
      navigator.serviceWorker.getRegistration().then(registration => {
        if (!!registration && registration.active && registration.active.state && registration.active.state === 'activated') {
          this.registration = firebase.default.messaging().useServiceWorker(registration);
          console.log('Registation below !!! ')
          console.log(this.registration)
          return registration
        } else {
          console.warn('No active service worker found, not able to get firebase messaging');
        }
      });
      */
    }
  }

  saveMessagingDeviceToken() {
    return firebase.default.messaging().getToken()
      .then((currentToken) => {
        if (currentToken) {
          console.log('Got FCM device token:', currentToken);
          // Save the Device Token to the datastore.
          firebase.default.database()
            .ref('/fcmTokens')
            .child(currentToken)
            .set(this.user.uid);
        } else {
          // Need to request permissions to show notifications.
          return this.requestNotificationsPermissions();
        }
      }).catch((err) => {
        this.snackBar.open('Unable to get messaging token.', null, {
          duration: 5000
        });
        console.error(err);
      });
  };

  requestNotificationsPermissions() {
    console.log('Requesting notifications permission...');
    return firebase.default.messaging().requestPermission()
      // Notification permission granted.
      .then(() => this.saveMessagingDeviceToken())
      .catch((err) => {
        this.snackBar.open('Unable to get permission to notify.', null, {
          duration: 5000
        });
        console.error(err);
      });
  };

  isserviceworker() {
    return navigator.serviceWorker.getRegistration().then(registration => {
      console.log(registration)
      if (!!registration && registration.active && registration.active.state && registration.active.state === 'activated' && !this.platform.is('ios')) {
        return true
      }
      return false
    });
  };

  setreg() {
    if (this.isBrowser) {
      console.log('Service Worker getting registation')
      this.window.navigator.serviceWorker.getRegistration().then(registration => {
        console.log(registration)
        if (!!registration && registration.active && registration.active.state && registration.active.state === 'activated' && !this.platform.is('ios')) {
          this.registration = firebase.default.messaging().useServiceWorker(registration);
          console.log(registration)
          return registration
        } else {
          console.warn('No active service worker found, not able to get firebase messaging');
          this.requireslogin = true
        }
      });
    }
  }

  updateToken(token) {
    if (this.isBrowser) {
      if (!this.user || !this.user.uid || !this.user && !this.user.uid == null) {
        const data = {
          token: token,
          uid: 'linky.app'
        }
        this.db.list('fcmTokens/').push(data);
        if (this.currentowner) {
          const data = {
            token: token,
            uid: this.currentowner
          }
          this.db.list('notifications/' + this.currentowner + 'subs/').push(token)
          if(this.deviceupdate === true){
            this.db.object('users/' + this.user.uid + '/token').update(data)
          }
        }
      } else {
        const data = {
          uid: this.user.uid,
          token: token
        }
        this.db.list('fcmTokens/').push(data)
        if (this.currentowner) {
          const data = {
            token: token,
            uid: this.currentowner
          }
          this.db.list('notifications/' + this.currentowner + 'subs/').push(token)
          if(this.deviceupdate === true){
            this.db.object('users/' + this.user.uid + '/token').update(data)
          }
        }
      }
    }
  }
  updateParams(paramskey: string) {
    console.log('Params inside messaging service ' + paramskey)
    this.currenturl = paramskey;
    return
  }
  updatepageowner(uid) {
    this.currentowner = uid
    return
  }
  getCookie() {
    if (isPlatformBrowser(this.platformId) == true) {
      var reffuid = this.window.localStorage.getItem('refferal');
      console.log(reffuid)
      this.cookiedata = reffuid;
      return reffuid;
    }
  }
  followuser() {
    console.log(this.currentowner);
    console.log(this.user)
    if (this.user && this.currentowner && this.user.uid) {
      this.db.list('/social/' + this.currentowner).push(this.user.uid);
      this.snackBar.open('Following', 'ok!', {
        duration: 9000
      });
    }
    if (!this.user) {
      return 'Login Required' 
      //this.dialog.open(LoginComponent)
    }
    if (this.currentowner && this.user && this.user.uid && this.currentowner == this.user.uid) {
      this.deviceupdate = true;
      this.snackBar.open('Notification device updated', 'ok!', {
        duration: 9000
      });
    }
  }
  sendUploadData(tokendata) {
    console.log('Subscribing' + tokendata + 'to url to user content' + this.currenturl + 'this current user ' + this.currentowner)
    if (this.currenturl && this.currenturl != '' && this.currenturl != 'dashboard' && this.currentowner) {
      let send = {
        token: tokendata,
        uid: this.cookiedata,
        date: Date.now().toString,
      }
      console.log('sending this data ' + send + ' to the cloud')
      console.log(send)
      this.db.list(this.cookiedata + '/subs').push(send)
      const data2 = {
        token: tokendata,
        date: Date.now().toString,
      }
      console.log('Sending data to cloud notifications')
      this.db.list('notifications/' + this.currentowner + '/subs').push(data2)
    } else {
      return
    }
  }

  getPermission() {
    this.followuser()
    if (this.isBrowser && !this.platform.is('ios')) {
      //this.setreg()
      console.log('get permission function')
      // console.log('registration from getPermission function ' + this.registration )
      return firebase.default.messaging().requestPermission().catch(err => {
        this.global.handleErr(err)
      })
        .then(() => {
          this.token = firebase.default.messaging().getToken()
          console.log('Notification permission granted.');
          console.log(this.token)
          return this.token
        })
        .then(token => {
          if(token) {
            this.global.firelitics('notificationssub', token)
            console.log(token)
            this.tokenraw = token
            this.updateToken(this.tokenraw)
            return this.tokenraw
          }
        })
        .catch((err) => {
          this.basicerr(err)
          console.log('Unable to get permission to notify.', err);
        });
    }
    if (this.platform.is('ios')) {
      this.snackBar.open('Ohh hey, ios does not support this feature on the web', 'Contact Apple!', {
        verticalPosition: 'top'
      }).onAction()
        .subscribe(() => {
          this.snackBar.open('Apple might support web apps in a few years', '', {
            duration: 3000,
            verticalPosition: 'top'
          });
        });
    }
  }

  requesttoken() {
    if (this.platform.is('ios')) {
      let snack = this.snackBar.open('Ohh hey, ios does not support this feature on the web yet', 'Contact Apple!', {
        verticalPosition: 'top'
      }).onAction()
        .subscribe(() => {
          this.snackBar.open('Apple might support web apps in a few years', '', {
            duration: 3000,
            verticalPosition: 'top'
          });
          // Join waitlist for Ios App
        });
        return snack
    }
    if (this.isBrowser && this.token && this.tokenraw) { 
      return this.tokenraw
    } else {
      return this.getPermission()
    }
  }

  followafterlogin(uid) {

  }
  getUserState() {
    this.user = firebase.default.auth().currentUser
  }
  loggedAs(user) {
    console.log(user)
    this.user = user
  }
  getUserInfo(request) {
    this.getUserState()
    console.log('return uid and other info of ' + this.user)
    switch (request) {
      case 'user':
        return this.user
        break
      case 'img':
        return this.user.photoURL
        break
      case 'uid':
        return this.user.uid
        break
      case 'name':
        return this.user.displayName
        break
      case 'email':
        return this.user.email
        break
      case 'phone':
        return this.user.phoneNumber
        break
    }
  }
  sendMessage(data) {
    if(this.user && this.user.uid) {
    this.db.list('messages/' + this.user.uid).push(data)
      this.snackBar.open('Message Sent', 'ok!', {
        duration: 9000
      });
    }
  }

  basicerr(err) {
    if(err && err.code && err.message.includes('Failed to register a ServiceWorker')){
      this.snackBar.open('Failed to register a ServiceWorker', 'View Suggestion!', {
        duration: 9000
      }).onAction()
      .subscribe(() => {
          this.snackBar.open('Try refreshing the page, if persisits clear site settings', 'view err', {
            duration: 9000
          }).onAction()
          .subscribe(() => { 
            this.snackBar.open(err, 'ok!', {
              duration: 9000
            });
          })
        }
      );
    }
  }

}